.financial-metrics {
  @apply bg-white rounded-lg shadow p-6 mb-4;
}

.financial-metrics .financial-metrics-grid {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6;
}

.financial-metrics .metric-group {
  @apply space-y-3 p-4 rounded-lg border border-gray-100 hover:border-gray-200 transition-colors;
}

.financial-metrics .metric-group-title {
  @apply text-gray-900 font-medium text-lg mb-3;
}

.financial-metrics .metric-row {
  @apply flex justify-between items-center py-1.5 hover:bg-gray-50 rounded px-2 -mx-2 transition-colors;
}

.financial-metrics .metric-label {
  @apply text-gray-900 !important;
}

.financial-metrics .metric-value {
  @apply text-gray-900 font-medium !important;
}

.financial-metrics .metric-value.positive {
  @apply text-green-600 !important;
}

.financial-metrics .metric-value.negative {
  @apply text-red-600 !important;
}

/* For N/A values */
.financial-metrics .metric-value:empty::before,
.financial-metrics .metric-value:contains("N/A") {
  @apply text-gray-500 !important;
}

.financial-metrics .info-icon {
  @apply text-gray-400 hover:text-gray-600 cursor-help transition-colors;
  font-size: 0.875rem;
}

/* Enhanced tooltip */
.metric-tooltip {
  @apply z-50 max-w-xs bg-gray-900 text-white px-3 py-2 rounded text-sm leading-relaxed;
}

/* Add styles for threshold-based coloring */
.metric-value.threshold-positive {
  @apply text-green-600;
}

.metric-value.threshold-negative {
  @apply text-red-600;
}

/* Style for analytical comments in tooltip */
.metric-tooltip span.font-medium {
  @apply text-gray-200;
}

/* SWOT Analysis Styles */
.swot-grid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6;
}

.swot-item {
  @apply bg-white rounded-lg p-4 border border-gray-200 transition-all duration-200 hover:border-gray-300 hover:shadow-sm;
}

.swot-title {
  @apply text-lg font-medium mb-3 flex items-center gap-2;
}

.swot-icon {
  @apply w-5 h-5;
}

.swot-list {
  @apply space-y-2 text-gray-900 list-none;
}

.swot-list li {
  @apply pl-4 relative text-gray-900;
  position: relative;
}

.swot-list li::before {
  content: "•";
  @apply absolute left-0 text-gray-600;
}

/* SWOT specific colors */
.swot-strengths .swot-title {
  @apply text-green-700;
}

.swot-strengths .swot-icon {
  @apply text-green-600;
}

.swot-weaknesses .swot-title {
  @apply text-red-700;
}

.swot-weaknesses .swot-icon {
  @apply text-red-600;
}

.swot-opportunities .swot-title {
  @apply text-blue-700;
}

.swot-opportunities .swot-icon {
  @apply text-blue-600;
}

.swot-threats .swot-title {
  @apply text-orange-700;
}

.swot-threats .swot-icon {
  @apply text-orange-600;
}

/* Hover effects */
.swot-item:hover {
  @apply bg-white;
}

/* Add responsive padding adjustments */
@media (max-width: 768px) {
  .swot-grid {
    @apply gap-4;
  }
  
  .swot-item {
    @apply p-3;
  }
  
  .swot-title {
    @apply text-base mb-2;
  }
  
  .swot-list {
    @apply text-sm;
  }
  
  .swot-list li {
    @apply text-gray-900;
  }
}

/* Competition section */
.competition-table {
  @apply min-w-full text-left;
}

.competition-table th {
  @apply py-2 px-4 font-medium text-gray-900 border-b;
}

.competition-table td {
  @apply py-2 px-4 text-gray-900 border-b;
}

/* Growth Prospects and other text sections */
.section-content {
  @apply text-gray-900;
}

/* Key Developments and Risk Factors */
.list-disc li {
  @apply text-gray-900;
}

/* AI Disclaimer */
.ai-disclaimer-text {
  @apply text-gray-900;
}

/* Description sections */
p {
  @apply text-gray-900;
}

/* Metric descriptions */
.metric-tooltip {
  @apply z-50 max-w-xs bg-gray-900 text-white px-3 py-2 rounded text-sm leading-relaxed;
}

/* SWOT specific colors - keeping these vibrant for visual hierarchy */
.swot-strengths .swot-title {
  @apply text-green-700;
}

.swot-strengths .swot-icon {
  @apply text-green-600;
}

.swot-weaknesses .swot-title {
  @apply text-red-700;
}

.swot-weaknesses .swot-icon {
  @apply text-red-600;
}

.swot-opportunities .swot-title {
  @apply text-blue-700;
}

.swot-opportunities .swot-icon {
  @apply text-blue-600;
}

.swot-threats .swot-title {
  @apply text-orange-700;
}

.swot-threats .swot-icon {
  @apply text-orange-600;
}

/* Financial metrics section */
.financial-metrics {
  @apply bg-white rounded-lg shadow p-6 mb-4;
}

.financial-metrics .metric-group-title {
  @apply text-gray-900 font-medium text-lg mb-3;
}

.financial-metrics .metric-label {
  @apply text-gray-900 !important;
}

.financial-metrics .metric-value {
  @apply text-gray-900 font-medium !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .swot-grid {
    @apply gap-4;
  }
  
  .swot-item {
    @apply p-3;
  }
  
  .swot-title {
    @apply text-base mb-2;
  }
  
  .swot-list {
    @apply text-sm;
  }
  
  .swot-list li {
    @apply text-gray-900;
  }
} 