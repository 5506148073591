/* Add this at the top of the file */
.settings-dialog-title {
  font-size: 24px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e5e7eb;
}

.settings-dialog {
    width: 100%;
    max-width: 800px;
  }
  
  .rule-container {
    background-color: #f3f4f6;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    position: relative;
  }
  
  .color-picker {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    padding: 0;
    margin-right: 8px;
  }
  
  .condition-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .condition-select, .condition-input {
    flex: 1;
    margin-right: 8px;
    padding: 4px 8px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
  }
  
  .remove-condition-btn {
    background-color: #ef4444;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .add-condition-btn {
    background-color: #10b981;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin-top: 8px;
    cursor: pointer;
  }
  
  .remove-rule-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #ef4444;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .add-rule-btn {
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    margin-top: 16px;
    cursor: pointer;
  }
  
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
  
  .cancel-btn, .save-btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: #e5e7eb;
    color: #374151;
    margin-right: 8px;
  }
  
  .save-btn {
    background-color: #3b82f6;
    color: white;
  }