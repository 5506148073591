.earnings-widget {
    width: 100%; /* Make it span across the page */
    padding: 20px; /* Increased padding for better spacing */
    border: 1px solid #ddd;
    margin: 20px 0;
    box-sizing: border-box; /* Ensure padding and border are included in the element's width and height */
    background-color: #f9f9f9; /* Light background color for better contrast */
    cursor: pointer; /* Change cursor to pointer to indicate clickability */
  }
  
  .widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  .widget-header h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333; /* Darker color for heading */
  }
  
  .timeframe-select {
    padding: 5px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .earnings-bar {
    display: flex;
    width: 100%; /* Ensure the bar spans the full width of the container */
    height: 40px; /* Increased height for better visibility */
    margin-bottom: 20px; /* Increased margin for better spacing */
    border-radius: 5px; /* Rounded corners for a smoother look */
    overflow: hidden; /* Prevent overflow */
  }
  
  .bar-increase {
    background-color: #4CAF50; /* Slightly lighter green */
    text-align: center;
    color: white;
    line-height: 40px; /* Adjusted line-height to match bar height */
    font-weight: bold; /* Bold font for better readability */
  }
  
  .bar-decrease {
    background-color: #f44336; /* Slightly lighter red */
    text-align: center;
    color: white;
    line-height: 40px; /* Adjusted line-height to match bar height */
    font-weight: bold; /* Bold font for better readability */
  }
  
  .earnings-details {
    display: flex;
    justify-content: space-between;
    padding: 10px 0; /* Added padding for better spacing */
  }
  
  .earnings-details > div {
    flex: 1;
    padding: 0 20px; /* Added padding to each detail section */
  }
  
  .earnings-details h3 {
    margin-top: 0;
    font-size: 1.2em; /* Increased font size */
    color: #333; /* Darker color for headings */
    margin-bottom: 10px; /* Space below headings */
  }
  
  .earnings-details ul {
    list-style: none;
    padding-left: 0;
    margin: 0; /* Removed default margin */
  }
  
  .earnings-details li {
    margin-bottom: 5px; /* Space between list items */
    font-size: 1em; /* Set font size for list items */
    color: #555; /* Lighter color for list items */
  }
  