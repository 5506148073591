.status-bar {
  width: 100%;
  height: 24px;
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: white;
  padding: 4px 0;
  user-select: text;
}

.status-bar.info {
  background-color: #3b82f6;
}

.status-bar.success {
  background-color: #22c55e;
}

.status-bar.warning {
  background-color: #f59e0b;
}

.status-bar.error {
  background-color: #ef4444;
}

.header {
  background-color: #ffffff;
  padding: 0 16px 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 32px;
}

.header .logo {
  display: flex;
  align-items: center;
}

.header .logo .title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.header .logo h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.header .logo h2 {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  margin: 0;
  line-height: 1.2;
}

.header .right-section {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.header .gauge-container {
  display: flex;
  justify-content: center;
}

.header .login {
  display: flex;
  align-items: center;
}

.welcome-message {
  font-size: 14px;
  font-weight: bold;
}

.lightning-icon {
  font-size: 60px;
  color: orange;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 1000px;
  width: 100%;
  position: relative;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.title-container h1 {
  margin: 0;
  font-size: 20px;
}

.title-container h2 {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.right-section {
  display: flex;
  align-items: center;
}

.trial-banner {
  background-color: #4285f4;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 15px;
}

.right-section {
  display: flex;
  align-items: center;
}

.trial-banner {
  margin-right: 20px;
  padding: 8px 16px;
  background-color: #4285f4;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}

.user-menu-container {
  position: relative;
}

.user-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.user-icon {
  width: 40px;
  height: 40px;
  background-color: #4285f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.user-initials {
  font-size: 16px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  min-width: 150px;
  z-index: 1000;
  margin-top: 5px;
}

.dropdown-item {
  padding: 10px;
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.user-email {
  font-weight: bold;
  border-bottom: 1px solid #eee;
}

.user-menu-container {
  position: relative;
}

.logo-image {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.mobile-warning-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 90%;
  width: 320px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mobile-warning-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mobile-warning-content {
  text-align: center;
}

.mobile-warning-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.mobile-warning-content p {
  margin-bottom: 1.5rem;
  color: #666;
  line-height: 1.4;
}

.mobile-warning-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.mobile-warning-button:hover {
  background-color: #0056b3;
}