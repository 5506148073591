/* ColumnSettingsModal.css */

.column-settings-modal {
  background-color: #fff;
  max-width: 600px;
  margin: 0 auto;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
}

.modal-instructions {
  margin-bottom: 10px;
  font-size: 1em;
}

.column-counter {
  margin: 0;
  font-weight: bold;
}

/* Updated styles for the grid layout */
.columns-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.column-item {
  display: flex;
  align-items: center;
}

.column-item label {
  display: flex;
  align-items: center;
  width: 100%;
}

.column-item input[type="checkbox"] {
  margin-right: 10px;
}

.fixed-column {
  color: #555;
}

.fixed-tag {
  background-color: #007bff;
  color: #fff;
  font-size: 0.75em;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: auto;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-weight: bold;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  padding: 15px 20px;
}

.action-left {
  display: flex;
  align-items: center;
}

.action-right {
  display: flex;
  align-items: center;
}

.modal-actions button {
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
}

.primary-button {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.primary-button:hover {
  background-color: #0056b3;
}

.secondary-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
}

.secondary-button:hover {
  background-color: #e0e0e0;
}

.column-item.selected {
  background-color: #f0f8ff;
}

@media (max-width: 600px) {
  .column-settings-modal {
    width: 90%;
  }
  
  .columns-grid {
    grid-template-columns: 1fr;
  }
}
