/* CompanyRow.css */

.earnings-estimate-icon {
    color: #f97316; /* Tailwind's orange-500 */
    font-size: 0.75em;
    margin-right: 0.25rem;
    transition: color 0.2s ease;
}

.earnings-estimate-icon:hover {
    color: #ea580c; /* Tailwind's orange-600 */
}

.earnings-estimate-tooltip-container {
    position: relative;
    display: inline-block;
}

.earnings-estimate-tooltip {
    visibility: hidden;
    background-color: #1f2937; /* Tailwind's gray-800 */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: fixed; /* Changed from absolute to fixed */
    z-index: 9999; /* Very high z-index */
    font-size: 0.75rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
}

.earnings-estimate-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
}

.earnings-estimate-tooltip-container:hover .earnings-estimate-tooltip {
    visibility: visible;
    opacity: 1;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
}

.group:hover .group-hover\:text-blue-600 {
  color: #2563eb;
}

.company-news-container {
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  padding: 1.5rem;
}

.company-news-header {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.company-news-content {
  padding: 0;
}

.company-news-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.375rem;
  transition: background-color 150ms;
  border-bottom: 1px solid #e5e7eb;
}

.company-news-item:last-child {
  border-bottom: none;
}

.company-news-item:hover {
  background-color: #f3f4f6;
}

.company-news-image {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 0.375rem;
  flex-shrink: 0;
}

.company-news-text {
  min-width: 0;
  flex: 1;
  margin-left: 1rem;
}

.company-news-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.25rem;
}

.company-news-meta {
  font-size: 0.75rem;
  color: #6b7280;
}

.company-name-news-icon {
  color: #6b7280;  /* gray-500 */
  height: 0.875rem;
  width: 0.875rem;
  margin-left: 0.25rem;
}

.company-name-news-icon:hover {
  color: #3b82f6;  /* blue-500 */
}

.news-indicator {
  display: inline-block;
  margin-left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #60a5fa;  /* blue-400 */
  position: relative;
  top: -1px;
}

.news-indicator:hover {
  background-color: #3b82f6;  /* blue-500 */
}

.news-indicator-container {
  position: relative;
  display: inline-block;
}

.news-indicator-fixed {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: 4px;
  margin-right: 2px;
  border-radius: 50%;
  background-color: #2563eb;
  position: relative;
  top: -2px;
  transition: transform 0.15s ease, background-color 0.15s ease;
  cursor: help;
}

.news-indicator-fixed:hover {
  background-color: #1d4ed8;
  transform: scale(1.2);
}

.news-indicator-tooltip {
  visibility: hidden;
  background-color: #1f2937;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: fixed;
  z-index: 9999;
  font-size: 0.75rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.news-indicator-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1f2937 transparent transparent transparent;
}

.news-indicator-container:hover .news-indicator-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Add these styles for the industry column */
td[data-column="industry"] {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td[data-column="industry"]:hover {
  white-space: normal;
  overflow: visible;
}

/* Dropdown menu styling */
.company-dropdown {
  position: relative;
}

.company-dropdown-button {
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-dropdown-button:hover {
  background-color: #f3f4f6;
}

.company-dropdown-button:active {
  background-color: #e5e7eb;
  transform: scale(0.95);
}

.company-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0.5rem;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 50;
  width: 200px;
  overflow: hidden;
  transform-origin: top right;
  animation: dropdown-appear 0.2s ease-out;
}

@keyframes dropdown-appear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.company-dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #4b5563;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  transition: all 0.15s ease;
}

.company-dropdown-item:hover {
  background-color: #f9fafb;
}

.company-dropdown-item:active {
  background-color: #f3f4f6;
}

.company-dropdown-item.watchlist:hover {
  color: #2563eb;
  background-color: #eff6ff;
}

.company-dropdown-item.not-interested:hover {
  color: #dc2626;
  background-color: #fef2f2;
}

.company-dropdown-item.delete:hover {
  color: #dc2626;
  background-color: #fef2f2;
}

.company-dropdown-item .icon {
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
}
