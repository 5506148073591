.market-analysis-container {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.market-header {
  @apply flex items-center mb-4;
}

.market-header h2 {
  @apply text-xl font-bold text-gray-900;
}

.market-header .icon {
  @apply text-blue-600 mr-2;
}

.header-left {
  flex: 0 0 auto;
}

.header-center {
  @apply flex-grow flex justify-center items-center space-x-6;
}

.header-right {
  flex: 0 0 auto;
}

.market-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.market-breadth {
  display: flex;
  gap: 1.5rem;
}

.advancing-badge,
.declining-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.advancing-badge {
  background-color: #ecfdf5;
  color: #065f46;
}

.advancing-badge svg {
  color: #059669;
}

.declining-badge {
  background-color: #fef2f2;
  color: #991b1b;
}

.declining-badge svg {
  color: #dc2626;
}

.phase-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4b5563;
  font-size: 0.875rem;
  white-space: nowrap;
}

.expand-icon {
  display: inline-flex;
  align-items: center;
  color: #6b7280;
  margin-left: 0.25rem;
}

.expand-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #6b7280;
}

.expand-button:hover {
  color: #374151;
}

.market-phase {
  color: #6b7280;
  font-size: 0.875rem;
}

.market-cycle-indicator {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 0.5rem;
  margin: 1rem 0;
}

.cycle-phase {
  flex: 1;
  padding: 1rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.cycle-phase.active-phase {
  background: #f0f9ff;
  border: 1px solid #93c5fd;
}

.phase-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.phase-number {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e7eb;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.875rem;
}

.active-phase .phase-number {
  background: #3b82f6;
  color: white;
}

.phase-name {
  font-weight: 600;
  font-size: 0.875rem;
}

.typical-sectors {
  font-size: 0.75rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.phase-description {
  font-size: 0.75rem;
  color: #6b7280;
  line-height: 1.25;
}

.confidence-badge {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background: #dbeafe;
  color: #1e40af;
  border-radius: 9999px;
  margin-left: auto;
}

.market-content {
  padding: 0 1rem 1rem;
}

.content-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.section-title {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.section-text {
  color: #4b5563;
  line-height: 1.5;
}

.sector-performance {
  margin-top: 1rem;
}

.sector-group {
  margin-bottom: 1rem;
}

.sector-group-header {
  padding: 0.5rem;
  background: #f3f4f6;
  font-weight: 500;
  border: 1px solid #e5e7eb;
  border-bottom: none;
}

.sector-table {
  width: 100%;
  border-collapse: collapse;
}

.sector-table th,
.sector-table td {
  padding: 0.5rem;
  text-align: left;
  border: 1px solid #e5e7eb;
}

.sector-table th {
  background: #f9fafb;
  font-weight: 500;
  color: #374151;
}

.sector-table td {
  color: #111827;
}

.numeric-cell {
  text-align: right;
}

.positive-value {
  color: #059669;
}

.negative-value {
  color: #dc2626;
}

.breadth-cell {
  text-align: right;
  color: #4b5563;
}

.advancing-declining {
  display: flex;
  gap: 1rem;
  margin: 1rem;
}

.market-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.advancing {
  background: #ecfdf5;
  color: #065f46;
}

.declining {
  background: #fef2f2;
  color: #991b1b;
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.market-indicators {
  display: flex;
  gap: 1rem;
}

.summary-card,
.highlight-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.summary-title,
.highlight-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.key-observations {
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
}

.observation-card {
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 1rem;
}

.observation-header {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #4b5563;
}

.observation-implication {
  margin-top: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.highlight-section {
  margin-bottom: 1.5rem;
}

.highlight-section:last-child {
  margin-bottom: 0;
}

.icon-green { color: #059669; }
.icon-red { color: #dc2626; }
.icon-blue { color: #2563eb; }
.icon-warning { color: #d97706; }
.icon-success { color: #059669; }

.market-risks-opportunities {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.risk-section,
.opportunity-section {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.sector-table th,
.sector-table td {
  text-align: right;
  padding: 0.75rem 1rem;
}

.sector-table th:first-child,
.sector-table td:first-child {
  text-align: left;
}

.fear-greed-indicator {
  @apply px-3 py-1 rounded-full bg-gray-100 transition-colors duration-200;
}

.fear-greed-indicator:hover {
  @apply bg-gray-200;
}

/* Update Market Overview styles */
.market-overview-container {
  @apply bg-white rounded-lg shadow-sm p-4;
}

.market-overview-container .market-header {
  @apply flex items-center mb-8;
}

.market-overview-container .market-header h2 {
  @apply text-3xl font-bold text-gray-900;
  line-height: 1;
}

.market-overview-container .market-header .icon {
  @apply text-blue-600 mr-3;
  font-size: 24px;
}

.market-overview-date {
  @apply text-sm text-gray-600 mb-4;
}

.market-overview-section {
  @apply mb-6;
}

.market-overview-section h3 {
  @apply text-xl font-semibold mb-4 flex items-center;
}

.market-overview-section h3 .icon {
  @apply mr-2;
}

/* News Items */
.news-item {
  @apply mb-4 p-3 bg-gray-50 rounded;
}

.news-item h5 {
  @apply font-semibold;
}

.news-item .details {
  @apply text-sm text-gray-700 mt-1;
}

.news-item .impact {
  @apply text-sm text-gray-600 mt-2 italic;
}

/* Sector Movements */
.sector-move {
  @apply mb-6;
}

.sector-move h4 {
  @apply font-semibold text-lg mb-3;
}

.sector-table-container {
  @apply overflow-x-auto;
}

.sector-table {
  @apply w-full text-sm;
}

.sector-table th {
  @apply text-left py-2 border-b;
}

.sector-table td {
  @apply py-2 border-b;
}

.sector-table td.price {
  @apply text-right;
}

.sector-commentary {
  @apply text-sm text-gray-600 mt-2;
}

/* Upcoming Events */
.events-container {
  @apply space-y-4;
}

.event-section h4 {
  @apply text-lg font-medium mb-3;
}

.event-item {
  @apply mb-2;
}

.event-item .title {
  @apply text-base font-medium;
}

.event-item .description {
  @apply text-sm text-gray-600;
}

/* Global Considerations */
.global-item {
  @apply mb-3;
}

.global-item h4 {
  @apply text-lg font-medium mb-2;
}

.global-item p {
  @apply text-sm text-gray-700;
}

/* Investment Recommendations */
.recommendation-item {
  @apply mb-3 p-3 bg-gray-50 rounded;
}

.recommendation-item h4 {
  @apply font-medium;
}

.recommendation-item p {
  @apply text-sm text-gray-600 mt-1;
}

/* Closing Summary */
.closing-summary {
  @apply mt-6 p-4 bg-gray-50 rounded;
}

.closing-summary h3 {
  @apply font-semibold mb-2;
}

.closing-summary p {
  @apply text-sm text-gray-700;
}

/* Icon Colors */
.icon-alert {
  @apply text-amber-500;
}

.icon-trending {
  @apply text-emerald-500;
}

.icon-target {
  @apply text-indigo-500;
}

/* Add new styles for specific sections */
.market-overview-recommendations {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.recommendation-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  transition: transform 0.2s ease-in-out;
}

.recommendation-card:hover {
  transform: translateY(-2px);
}

.stock-highlight-row {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  transition: transform 0.2s ease-in-out;
}

.stock-highlight-row:hover {
  transform: translateX(4px);
  border-color: #93c5fd;
}

.price-change-positive {
  color: #059669;
  font-weight: 600;
}

.price-change-negative {
  color: #dc2626;
  font-weight: 600;
}

.market-overview-summary {
  background: linear-gradient(135deg, #1e40af0d 0%, #3b82f60d 100%);
  border-radius: 8px;
  padding: 1.25rem;
  margin-top: 1.5rem;
  border: 1px solid #e2e8f0;
}

/* You can adapt or add more styling as needed */

/* Add new icon colors */
.icon-calendar {
  @apply text-purple-500;
}

.icon-globe {
  @apply text-cyan-600;
}

/* Update section headers to be consistent */
.market-overview-section h3 {
  @apply text-xl font-semibold mb-4 flex items-center;
}

/* Update subsection headers */
.market-overview-section h4 {
  @apply text-lg font-medium mb-2;
}

/* Update event section headers */
.event-section h4 {
  @apply text-lg font-medium mb-3;
}

/* Update event items */
.event-item .title {
  @apply text-base font-medium;
}

/* Update global considerations */
.global-item h4 {
  @apply text-lg font-medium mb-2;
} 