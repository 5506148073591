/* App.css */

@import 'Footer.css';
@import 'EconomicEventRow.css';
@import 'NewsRow.css';

.header {
    background-color: #ffffff;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s ease;
  }
  
  .header .logo:hover {
    opacity: 0.9;
  }
  
  .header .logo .logo-image {
    height: 48px;
    width: 48px;
    object-fit: contain;
    transition: filter 0.3s ease;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
  }
  
  .header .logo:hover .logo-image {
    animation: pulse 1s infinite;
  }
  
  .header .logo .logo-image {
    height: 48px;
    width: 48px;
    object-fit: contain;
    transition: filter 0.3s ease;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
  }
  
  .header .logo .title-container {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }
  
  .header .logo .title-container h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
  }
  
  .header .logo .title-container h2 {
    font-size: 12px;
    color: #6b7280;
    line-height: 1.2;
  }
  
  .header .right-section {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .header .right-section img {
    height: 48px;
  }
  
  .header .right-section .login {
    display: flex;
    align-items: center;
  }
  
  .header .right-section .login img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
  
  .container {
    padding-top: 0; /* Remove top padding */
    padding-bottom: 16px; /* Increase bottom padding */
  }
  
  .market-news strong {
    font-size: 2rem; /* 2xl */
    color: #2563EB; /* Tailwind's blue-600 */
    font-weight: bold;
  }
  
  .market-news {
    background-color: #DBEAFE; /* Tailwind's blue-100 */
    padding: 1rem; /* 4 */
    border-radius: 0.375rem; /* md */
  }
  
  .market-news p {
    color: #1E3A8A; /* Tailwind's blue-700 */
  }

.text-lighter {
  color: #6b7280; /* Adjust this color as needed */
  font-weight: normal; /* Ensure the text is not bold */
}

.group:hover .group-hover\:block {
  display: block;
  z-index: 100;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.modal-content {
  outline: none;
}

/* Add these styles at the end of your App.css file */

.login-button.w-full {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.dropdown-item.w-full {
  width: 100%;
  text-align: center;
  color: #dc3545;
  background-color: white;
  border: 1px solid #dc3545;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-item.w-full:hover {
  background-color: #f8d7da;
}

.profile-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.profile-modal-email {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.profile-modal-button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-modal-button-blue {
  background-color: #4285f4;
  color: white;
  border: none;
}

.profile-modal-button-blue:hover {
  background-color: #3367d6;
}

.profile-modal-button-red {
  background-color: white;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.profile-modal-button-red:hover {
  background-color: #f8d7da;
}

.table {
  display: table;
  width: 100%;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
}

.draggable-column {
  cursor: move;
}

.draggable-column.is-dragging {
  background-color: #e2e8f0;
}

/* Adjust the placeholder style if necessary */

/* Remove flex styles from table cells */
th, td {
  display: table-cell;
  vertical-align: middle;
}

/* Optional: Style adjustments for dragging */
.draggable-column {
  cursor: move;
}

.draggable-column.is-dragging {
  background-color: #e2e8f0;
}

/* Style for the drag handle */
.cursor-move {
  cursor: move;
  display: flex;
  align-items: center;
}

.cursor-move:hover {
  color: #374151; /* Tailwind's gray-700 */
}

/* In your CSS file */
.drag-handle {
  opacity: 0.6;
}

.drag-handle:hover {
  opacity: 1;
}

/* Add these styles to your existing App.css */

.market-pulse-advancing {
  background-color: rgba(134, 239, 172, 0.25); /* light green with transparency */
  color: #1a2e1a;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}

.market-pulse-declining {
  background-color: rgba(252, 165, 165, 0.25); /* light red with transparency */
  color: #2e1a1a;
  padding: 2px 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}

/* Add this new class */
.market-analysis {
  margin-bottom: 2rem; /* or 32px */
}

/* If you're using the neutral tag specifically */
.market-analysis.neutral {
  margin-bottom: 2rem;
}

/* If you need to target the last element in the market analysis section */
.market-analysis :last-child {
  margin-bottom: 2rem;
}

/* Add this to your existing styles */
.market-analysis-container {
  margin-bottom: 2.5rem; /* 40px */
}

/* Optional: Add spacing after the buttons container */
.category-buttons {
  margin-top: 1rem; /* 16px */
}

/* Add or update these styles */
.sentiment-badge {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
}

.sentiment-positive {
  background-color: rgba(134, 239, 172, 0.25);
  color: #166534;
}

.sentiment-negative {
  background-color: rgba(252, 165, 165, 0.25);
  color: #991b1b;
}

.sentiment-neutral {
  background-color: rgba(253, 230, 138, 0.25);
  color: #854d0e;
}

/* Add these at the end of your App.css */

.ai-disclaimer-text {
  font-size: 1rem;  /* equivalent to text-xs */
  font-style: italic;
  color: #6B7280;  /* equivalent to text-gray-500 */
  margin-top: 1rem;  /* equivalent to mt-4 */
}

.ai-disclaimer-icon {
  color: #2563EB;  /* equivalent to text-blue-600 */
}

/* Add this to your CSS */
.dropdown-text {
  margin-left: 12px;  /* Add left margin */
  margin-right: 12px;
}

/* Add these styles */
.inline-flex {
  display: inline-flex;
  align-items: stretch; /* Allow items to stretch */
  gap: 0.5rem; /* Small gap between button and dropdown */
}

/* Style for the add symbol button container */
.add-symbol-button {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto 0;
  align-self: center;
  padding: 0.5rem;
  height: 2.5rem;
}

/* Ensure dropdown text and stacking works */
.dropdown-text {
  margin-right: 12px;
}

.filter-button {
  display: inline-flex;
  flex-direction: column;
  min-height: 2.5rem;
}

/* Add styles for custom tooltips */
[data-tooltip] {
  position: relative;
}

[data-tooltip]::before {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%;
  left: calc(100% + 8px);
  transform: translateY(-50%);
  padding: 0.5rem;
  background-color: #1f2937;
  color: white;
  border-radius: 0.375rem;
  font-size: 1rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
  z-index: 50;
  pointer-events: none;
}

[data-tooltip]:hover::before {
  opacity: 1;
  visibility: visible;
}

.color-settings-icon {
  font-size: 1.75rem;
  margin-right: 1rem;
  color: hsl(76, 89%, 26%);  /* Neutral gray by default */
  cursor: pointer;
  transition: all 0.3s ease;
}

.color-settings-icon:hover {
  transform: scale(1.05);
  color: #4285f4;  /* Google blue */
  filter: 
    drop-shadow(0 0 2px rgba(66, 133, 244, 0.4))   /* blue */
    drop-shadow(0 0 2px rgba(234, 67, 53, 0.4))    /* red */
    drop-shadow(0 0 2px rgba(251, 188, 5, 0.4))    /* yellow */
    drop-shadow(0 0 2px rgba(52, 168, 83, 0.4));   /* green */
}

/* Add specific style for color settings icon tooltip */
.color-settings-tooltip[data-tooltip]::before {
  left: auto;  /* Reset left position */
  right: calc(100% + 8px);  /* Position to the left of the icon */
}

/* Add specific styling for category icons */
.category-icon {
  min-width: 24px; /* Ensure consistent icon width */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add specific styling for dropdown menu items */
.dropdown-menu-item {
  background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
  border-left: 4px solid transparent;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease;
}

.dropdown-menu-item:hover {
  background: linear-gradient(135deg, #dbeafe 0%, #ffffff 100%);
  border-left: 4px solid #3b82f6;
}

/* Top level menu items - smaller gap */
.filter-button .dropdown-text {
  margin-left: 6px;
  margin-right: 0px;
}

/* Submenu items - larger gap */
[role="menuitem"] .category-icon {
  margin-right: 12px;
}

/* Remove any previous category-icon styling */

/* Target the outer span that contains the icon */
.category-icon.mr-6 {
  margin-right: 1rem !important; /* Using !important to ensure it overrides any other styles */
}

/* If needed, we can also be more specific */
button .category-icon.mr-6 {
  margin-right: 1rem !important;
}

.market-status-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.375rem 0.75rem;
  background-color: rgb(254, 243, 199);
  border: 1px solid rgb(251, 191, 36);
  border-radius: 0.375rem;
  margin-top: 0.5rem;
}

.market-status-message .status-icon {
  width: 1rem;
  height: 1rem;
  color: rgb(217, 119, 6);
}

.market-status-message .status-text {
  font-size: 0.875rem;
  color: rgb(146, 64, 14);
}

.market-status-message .status-timestamp {
  margin-left: 0.25rem;
  color: rgb(180, 83, 9);
}

.market-sectors {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.sector-group {
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
}

.sector-label {
  font-weight: 500;
  min-width: 4.5rem;
}

.sector-label-leading {
  color: rgb(22, 163, 74); /* green-600 */
}

.sector-label-lagging {
  color: rgb(220, 38, 38); /* red-600 */
}

.sector-list {
  color: rgb(75, 85, 99); /* gray-600 */
  font-size: 0.9375rem;
}

/* Market Analysis Header Indicators */
.market-header-indicator {
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.market-header-advancing {
  background-color: rgb(187, 247, 208);
  color: rgb(21, 128, 61);
}

.market-header-declining {
  background-color: rgb(254, 202, 202);
  color: rgb(185, 28, 28);
}

/* Optional separator between indicators */
.market-header-indicators {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

/* Add these new styles */
.market-cap-label {
  font-weight: 500;
  color: #111827;
}

.market-cap-range {
  color: #6B7280;
  font-weight: normal;
  margin-left: 0.5rem;
  font-size: 0.95em;
}

/* Add styles for the market data table */
.market-data-table th {
  font-weight: 500;
  padding: 0.75rem 1rem;
  white-space: nowrap;
}

.market-data-table td {
  padding: 0.75rem 1rem;
}

.market-data-value {
  font-variant-numeric: tabular-nums;
}

.sector-outlook {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.sector-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid transparent;
}

.sector-badge.positive {
  background-color: rgba(134, 239, 172, 0.25);
  color: #166534;
  border-color: #bbf7d0;
}

.sector-badge.negative {
  background-color: rgba(252, 165, 165, 0.25);
  color: #991b1b;
  border-color: #fecaca;
}

.sector-badge.neutral {
  background-color: rgba(253, 230, 138, 0.25);
  color: #854d0e;
  border-color: #fde68a;
}

/* Update sector outlook styles */
.sector-outlook-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sector-outlook {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex: 1;
}

.sector-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid transparent;
}

.sector-badge.positive {
  background-color: rgba(134, 239, 172, 0.25);
  color: #166534;
  border-color: #bbf7d0;
}

.sector-badge.negative {
  background-color: rgba(252, 165, 165, 0.25);
  color: #991b1b;
  border-color: #fecaca;
}

.sector-badge.neutral {
  background-color: rgba(253, 230, 138, 0.25);
  color: #854d0e;
  border-color: #fde68a;
}

/* AI Filter Modal */
.ai-filter-modal {
  max-width: 600px;
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

.ai-filter-modal form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.ai-filter-modal textarea {
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
  min-height: 120px;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out;
}

.ai-filter-modal textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.ai-filter-modal .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.ai-filter-modal button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.15s ease-in-out;
}

.ai-filter-modal button[type="button"] {
  background-color: white;
  border: 1px solid #e5e7eb;
  color: #6b7280;
}

.ai-filter-modal button[type="button"]:hover {
  background-color: #f9fafb;
  border-color: #d1d5db;
}

.ai-filter-modal button[type="submit"] {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.ai-filter-modal button[type="submit"]:hover {
  background-color: #2563eb;
}

.ai-filter-modal button[type="submit"]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* AI Filter Lists */
.ai-filter-list {
  background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
  border-left: 4px solid #3b82f6;
  position: relative;
  z-index: 1;
}

.ai-filter-list .category-icon {
  color: #3b82f6;
}

.ai-filter-list button[title="Delete list"] {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ai-filter-list:hover button[title="Delete list"] {
  opacity: 1;
}

.ai-filter-list button[title="Delete list"]:hover {
  color: #ef4444;
}

/* AI Filter Menu Item */
.ai-filter-menu-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-top: auto;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  background: linear-gradient(135deg, #eff6ff 0%, #ffffff 100%);
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 1;
}

.ai-filter-menu-item .ai-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: rgb(219 234 254);
  color: rgb(37 99 235);
  margin-right: 0.75rem;
  transition: all 0.2s ease;
}

.ai-filter-menu-item:hover {
  background: linear-gradient(135deg, #dbeafe 0%, #f8fafc 100%);
}

.ai-filter-menu-item:hover .ai-icon {
  background-color: rgb(191 219 254);
}

/* Tooltip styles */
#tooltip {
  z-index: 9999 !important;
  max-width: 300px;
  white-space: normal;
  text-align: left;
}

/* News indicator styles */
.news-indicator {
  display: inline-flex;
  align-items: center;
  margin-left: 0.25rem;
  font-size: 0.875rem;
}

.news-indicator svg {
  width: 1rem;
  height: 1rem;
}

.explanation-row {
  background: #f8fafc;
  padding: 0.5rem;
}

.explanation-content {
  font-size: 0.925rem;
  line-height: 1.5;
}

.description {
  color: #475569;
  margin-bottom: 0.5rem;
}

.metric-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #475569;
  margin-bottom: 0.25rem;
  flex-wrap: wrap;
}

.spacer {
  color: #cbd5e1;
  margin: 0 0.5rem;
}

.tech, .healthcare, .finance {
  padding: 0 0.375rem;
  border-radius: 2px;
  font-size: 0.875rem;
}

.tech { background: #e0f2fe; color: #0369a1; }
.healthcare { background: #dcfce7; color: #166534; }
.finance { background: #fef3c7; color: #92400e; }

.score { font-weight: 500; }

.risk-tag {
  background: #fee2e2;
  color: #991b1b;
  padding: 0 0.375rem;
  border-radius: 2px;
  font-size: 0.875rem;
}

.risks {
  color: #64748b;
}

.metrics-grid {
  display: grid;
  gap: 1.5rem;
}

.metric-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.metric-row {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #475569;
}

.metric-row > span:first-child {
  min-width: 120px;
  color: #64748b;
}

/* Sector tags */
.tech, .healthcare, .finance {
  padding: 0.125rem 0.5rem;
  border-radius: 3px;
  font-size: 0.875rem;
}

.tech {
  background: #e0f2fe;
  color: #0369a1;
}

.healthcare {
  background: #dcfce7;
  color: #166534;
}

.finance {
  background: #fef3c7;
  color: #92400e;
}

/* Risk indicators */
.score {
  font-weight: 500;
  color: #334155;
}

.risk-tag {
  background: #fee2e2;
  color: #991b1b;
  padding: 0.125rem 0.5rem;
  border-radius: 3px;
  font-size: 0.75rem;
}

/* Risk list */
.risks {
  color: #64748b;
  padding-left: 0.5rem;
  white-space: pre-wrap;
  line-height: 1.5;
}

.metrics-section {
  margin: 0.75rem 0;
  padding-left: 0.5rem;
  border-left: 3px solid #e5e7eb;
}

.section-title {
  font-weight: 600;
  color: #374151;
  margin-bottom: 0.5rem;
}

.metric-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.25rem 0;
}

.metric-label {
  font-weight: 500;
  color: #4b5563;
  min-width: 140px;
}

.metric-value {
  color: #1f2937;
}

/* Sector percentage values */
.tech-value, .healthcare-value, .finance-value {
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  margin-right: 0.5rem;
}

.tech-value {
  background-color: #dbeafe;
  color: #1e40af;
}

.healthcare-value {
  background-color: #dcfce7;
  color: #166534;
}

.finance-value {
  background-color: #fef3c7;
  color: #92400e;
}

/* Risk indicators */
.score-indicator, .risk-indicator {
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.score-indicator.low {
  background-color: #fee2e2;
  color: #991b1b;
}

.risk-indicator.high {
  background-color: #fee2e2;
  color: #991b1b;
}

/* Risk list styling */
.risks-list {
  margin-top: 0.5rem;
}

.risks-list ul {
  margin: 0.25rem 0 0 1rem;
  list-style-type: none;
}

.risks-list li {
  color: #4b5563;
  padding: 0.125rem 0;
}

.risks-list li::before {
  content: "•";
  color: #9ca3af;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Add this with the other AI filter styles */
.watchlist-item {
  background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
  border-left: 4px solid transparent;
  position: relative;
  z-index: 1;
}

.watchlist-item:hover {
  background: linear-gradient(135deg, #dbeafe 0%, #ffffff 100%);
  border-left: 4px solid #3b82f6;
}

/* Add this utility class at the bottom */
.responsive-scroll {
  width: 100%;
  overflow-x: auto; /* allows horizontal scroll if content is wide */
}

@keyframes pulse {
  0% { 
    transform: scale(1);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
  }
  50% { 
    transform: scale(1.05);
    filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.3));
  }
  100% { 
    transform: scale(1);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
  }
}

.header .logo:hover .logo-image {
  animation: pulse 1s infinite;
}

/* Add new styles for JSON formatting in explanation row */
.explanation-row .json-content {
  font-family: monospace;
  white-space: pre-wrap;
  padding-left: 1rem;
  margin-top: 0.5rem;
}

.explanation-row .json-key {
  color: #5c6606;
  font-weight: 600;
}

.explanation-row .json-value {
  color: #1a4d8c;
}

.explanation-row .json-string {
  color: #0f766e;
}

.explanation-row .json-number {
  color: #0369a1;
}

.explanation-row .json-bracket {
  color: #374151;
}

.explanation-row .json-indent {
  margin-left: 1.5rem;
}

.explanation-row td {
  padding: 1rem 1.25rem;
  color: #5c6606;
  font-size: 0.925rem;
  line-height: 1.4;
  position: relative;
  padding-left: 3.5rem;
}

.explanation-row td::before {
  content: '';
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%230f3057'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

/* Style for the section headers within explanation */
.explanation-row .section-header {
  font-weight: 600;
  color: #374151;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}

/* Add visual separation between sections */
.explanation-row .json-section {
  border-left: 2px solid #e5e7eb;
  margin: 0.5rem 0;
  padding-left: 0.75rem;
}

/* Style for arrays */
.explanation-row .json-array {
  margin-left: 1rem;
}

.explanation-row .json-array-item {
  position: relative;
  padding-left: 1rem;
}

.explanation-row .json-array-item::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #6b7280;
}

.section-title {
  color: #111827 !important; /* Tailwind's gray-900, or pick another dark color */
}

