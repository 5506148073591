.fear-greed-container {
  @apply bg-white rounded-lg shadow-sm p-4 mb-4;
}

.metric-card {
  @apply bg-white rounded-lg p-3 shadow-sm transition-all duration-200;
}

.metric-card:hover {
  @apply shadow-md transform -translate-y-0.5;
}

.gauge-container {
  @apply relative h-32 w-full;
}

.historical-chart {
  @apply h-64 bg-gray-50 rounded-lg p-4;
}

/* Add these classes to your existing tailwind.config.js if needed */

/* Add gradient backgrounds for sentiment levels */
.text-extreme-fear { color: #dc2626; }
.bg-extreme-fear { background-color: #fef2f2; }

.text-fear { color: #ea580c; }
.bg-fear { background-color: #fff7ed; }

.text-neutral { color: #4b5563; }
.bg-neutral { background-color: #f3f4f6; }

.text-greed { color: #059669; }
.bg-greed { background-color: #f0fdf4; }

.text-extreme-greed { color: #065f46; }
.bg-extreme-greed { background-color: #ecfdf5; }

/* Chart tooltip styling */
.nivo-tooltip {
  @apply shadow-lg rounded-lg border border-gray-200 !important;
  background: theme('colors.white') !important;
}

/* Gauge chart container */
.gauge-container svg {
  @apply w-full h-full;
}

/* Add these new styles */
.fear-greed-badge {
  @apply transition-all duration-200 shadow-sm;
}

.fear-greed-badge:hover {
  @apply shadow-md transform -translate-y-0.5;
}

/* Update existing sentiment colors with opacity variants */
.bg-extreme-fear { @apply bg-red-50; }
.bg-fear { @apply bg-orange-50; }
.bg-neutral { @apply bg-gray-50; }
.bg-greed { @apply bg-green-50; }
.bg-extreme-greed { @apply bg-emerald-50; }

/* Add hover states */
.fear-greed-badge:hover .bg-extreme-fear { @apply bg-red-100; }
.fear-greed-badge:hover .bg-fear { @apply bg-orange-100; }
.fear-greed-badge:hover .bg-neutral { @apply bg-gray-100; }
.fear-greed-badge:hover .bg-greed { @apply bg-green-100; }
.fear-greed-badge:hover .bg-extreme-greed { @apply bg-emerald-100; }

/* Update fear and greed header styles */
.fear-greed-header {
  @apply flex items-center justify-between mb-6 border-b border-gray-100 pb-4 bg-transparent;
}

.fear-greed-header h2 {
  @apply text-black; /* Force black text color */
  background: transparent; /* Ensure no background color */
}

.fear-greed-value {
  @apply flex items-center gap-2;
}

.fear-greed-score-container {
  @apply flex items-center gap-3 px-4 py-2 rounded-lg transition-all duration-200 
         hover:shadow-md hover:-translate-y-0.5;
}

.fear-greed-score {
  @apply text-2xl font-bold tracking-tight;
}

.fear-greed-rating {
  @apply px-3 py-1 rounded-full text-sm font-medium capitalize;
}

/* Update sentiment-specific styles */
.fear-greed-score-container.text-extreme-fear {
  @apply bg-red-50 border border-red-200;
}

.fear-greed-score-container.text-fear {
  @apply bg-orange-50 border border-orange-200;
}

.fear-greed-score-container.text-neutral {
  @apply bg-gray-50 border border-gray-200;
}

.fear-greed-score-container.text-greed {
  @apply bg-green-50 border border-green-200;
}

.fear-greed-score-container.text-extreme-greed {
  @apply bg-emerald-50 border border-emerald-200;
}

/* Rating badge styles */
.fear-greed-rating.extreme-fear {
  @apply bg-red-100 text-red-800;
}

.fear-greed-rating.fear {
  @apply bg-orange-100 text-orange-800;
}

.fear-greed-rating.neutral {
  @apply bg-gray-100 text-gray-800;
}

.fear-greed-rating.greed {
  @apply bg-green-100 text-green-800;
}

.fear-greed-rating.extreme-greed {
  @apply bg-emerald-100 text-emerald-800;
}

/* Add hover effects */
.fear-greed-score-container:hover {
  @apply shadow-md -translate-y-0.5;
  transition: all 0.2s ease-in-out;
}

/* Update sentiment background colors */
.bg-extreme-fear { 
  @apply bg-red-50;
  border: 1px solid theme('colors.red.200');
}

.bg-fear { 
  @apply bg-orange-50;
  border: 1px solid theme('colors.orange.200');
}

.bg-neutral { 
  @apply bg-gray-50;
  border: 1px solid theme('colors.gray.200');
}

.bg-greed { 
  @apply bg-green-50;
  border: 1px solid theme('colors.green.200');
}

.bg-extreme-greed { 
  @apply bg-emerald-50;
  border: 1px solid theme('colors.emerald.200');
}

/* Update sentiment text colors with background */
.fear-greed-rating.extreme-fear { 
  @apply text-red-800 bg-red-100;
}

.fear-greed-rating.fear { 
  @apply text-orange-800 bg-orange-100;
}

.fear-greed-rating.neutral { 
  @apply text-gray-800 bg-gray-100;
}

.fear-greed-rating.greed { 
  @apply text-green-800 bg-green-100;
}

.fear-greed-rating.extreme-greed { 
  @apply text-emerald-800 bg-emerald-100;
}

/* Add these styles at the end of the file */

/* Header-specific fear & greed indicator styles */
.fear-greed-indicator {
  display: none;
}

/* Update the market header indicators section */
.market-header-indicator {
  @apply inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium;
}

.market-header-advancing {
  @apply bg-green-100 text-green-800;
}

.market-header-declining {
  @apply bg-red-100 text-red-800;
}

.market-header-neutral {
  @apply bg-gray-100 text-gray-800;
}

/* Add sentiment-specific styles */
.market-header-neutral.extreme-fear {
  @apply bg-red-100 text-red-800;
}

.market-header-neutral.fear {
  @apply bg-orange-100 text-orange-800;
}

.market-header-neutral.neutral {
  @apply bg-gray-100 text-gray-800;
}

.market-header-neutral.greed {
  @apply bg-green-100 text-green-800;
}

.market-header-neutral.extreme-greed {
  @apply bg-emerald-100 text-emerald-800;
}

/* Reset and update tooltip styles */
.tooltip-wrapper {
  position: relative;
  isolation: isolate; /* Create new stacking context */
}

.tooltip-content {
  position: absolute;
  isolation: isolate;
  background: #1a1a1a;
  color: white;
  border-radius: 0.375rem;
  padding: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
  max-width: 24rem;
  pointer-events: none;
  font-size: 0.875rem;
  line-height: 1.4;
  
  /* Position above by default */
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
  
  /* Add arrow */
  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    background: #1a1a1a;
  }
}

/* Update container styles */
.fear-greed-indicators-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  z-index: 20; /* Higher than chart */
}

/* Remove all other z-index related styles */
.historical-chart {
  @apply h-64 bg-gray-50 rounded-lg p-4;
  position: relative;
  z-index: 10; /* Lower than indicators */
}

/* Style tooltip content */
.tooltip-content .font-medium {
  color: #fff;
  margin-bottom: 0.5rem;
}

.tooltip-content .text-sm {
  color: rgba(255, 255, 255, 0.9);
}

.tooltip-content .text-xs {
  color: rgba(255, 255, 255, 0.7);
}

.tooltip-content .italic {
  color: rgba(255, 255, 255, 0.8);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

/* Update ResponsiveLine chart container */
.nivo-line {
  z-index: 10; /* Same as chart container */
}

/* Make sure there's no conflicting background */
.bg-white {
  background-color: white !important;
} 