/* Main container for the Bond & Interest Rate Insights section */
.bond-insights-section {
    margin-top: 0;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    overflow: hidden;
    
    /* Add or ensure these: */
    width: 100%;
    max-width: 100%;
    margin: 0 auto; /* optional if you want it centered on large screens */
}
  
/* Section header: clickable area for folding/unfolding */
.bond-insights-section .section-header {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 1rem;
    cursor: pointer;
    background-color: white;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
}
  
.bond-insights-section .section-header:hover {
    background-color: #f1f1f1;
}
  
/* Header content: title and icon */
.bond-insights-section .header-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
  
/* Title styling */
.bond-insights-section h3 {
    margin: 0;
    font-weight: 600;
    font-size: 1.1rem;
}
  
/* New container for compact view */
.compact-bond-insights-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}
  
/* Pill-style small info blocks */
.bond-insights-pill {
    display: inline-flex;
    align-items: center;
    padding: 0.3rem 0.6rem;
    background-color: #eef0f5;
    border-radius: 4px;
    font-size: 0.85rem;
    color: #333;
    white-space: nowrap;
}
  
.bond-insights-pill .label {
    font-weight: 500;
    margin-right: 0.3rem;
}
  
/* Expanded content container */
.bond-insights-section .insights-content {
    padding: 1rem;
    background-color: #fff;
}
  
/* Tables */
.yield-curve-table,
.bond-analytics-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0.5rem;
}
  
.yield-curve-table th,
.yield-curve-table td,
.bond-analytics-table th,
.bond-analytics-table td {
    border: 1px solid #e3e3e3;
    padding: 0.6rem 0.8rem;
    text-align: left;
    font-size: 0.9rem;
}
  
.yield-curve-table th,
.bond-analytics-table th {
    background-color: #fafafa;
    font-weight: 600;
}
  
/* Margin top utility */
.mt-4 {
    margin-top: 1.5rem;
}
  
/* Error & warning messages (optional, if you want them consistent) */
.error-message, .warning-message {
    background-color: #fff4f4;
    border: 1px solid #ffd4d4;
    color: #b30000;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 4px;
}
  
/* Loading message */
.loading {
    padding: 1rem;
    text-align: center;
    color: #666;
}
  
/* Interpretation panel style */
.interpretation-panel {
    padding: 1rem;
    border: 1px solid #d9d9d9;
    background-color: #fafafa;
    border-radius: 6px;
    margin-top: 0.5rem;
}
.interpretation-panel h4 {
    margin-top: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
}
.interpretation-panel p {
    margin: 0.5rem 0 0;
    color: #555;
    line-height: 1.4em;
}
  
/* Extra highlight or label style if desired */
.interpretation-panel strong {
    color: #333;
    font-weight: 600;
}
  
/* We add 3 classes: negative-slope, flat-slope, and positive-slope */
.negative-slope {
    background-color: #ffe8e8; /* light red/pink tint */
    color: #a10000;
}
  
.flat-slope {
    background-color: #fff7d1; /* pale yellow */
    color: #665500;
}
  
.positive-slope {
    background-color: #eaffea; /* light green */
    color: #046300;
}