.macro-scenarios-section {
  margin-top: 0;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  overflow: hidden;
  
  /* Add or ensure these: */
  width: 100%;
  max-width: 100%;
  margin: 0 auto; /* optional if you want it centered on large screens */
}

.section-header {
  @apply flex items-center p-4 hover:bg-gray-50 border-b;
  padding: 0.6rem 1rem;
  background-color: white;
  border-bottom: 1px solid #e5e7eb;
}

.section-header h3 {
  @apply text-lg font-semibold text-gray-900;
}

.scenarios-content {
  @apply p-4;
}

.scenarios-grid {
  @apply grid gap-4 md:grid-cols-2 lg:grid-cols-3;
}

.scenario-card {
  @apply bg-white rounded-lg border p-4 hover:shadow-md transition-shadow;
}

.scenario-header {
  @apply flex justify-between items-start mb-3;
}

.scenario-title {
  @apply text-lg font-medium text-gray-900;
}

.probability-badge {
  @apply text-sm px-2 py-1 rounded-full;
}

.probability-badge.high {
  @apply bg-green-100 text-green-800;
}

.probability-badge.low {
  @apply bg-yellow-100 text-yellow-800;
}

.scenario-description {
  @apply text-gray-600 mb-4;
}

.scenario-details {
  @apply grid gap-4 mb-4;
}

.risks-section, .opportunities-section {
  @apply space-y-2;
}

.risks-section h5, .opportunities-section h5 {
  @apply flex items-center gap-2 font-medium text-gray-900;
}

.risks-section ul, .opportunities-section ul {
  @apply list-disc list-inside text-sm text-gray-600;
}

.sector-implications {
  @apply grid gap-3 pt-3 border-t;
}

.potential-winners, .potential-losers {
  @apply space-y-1;
}

.potential-winners h5, .potential-losers h5 {
  @apply flex items-center gap-2 text-sm font-medium;
}

.icon-warning {
  @apply text-yellow-500;
}

.icon-success {
  @apply text-green-600;
}

.icon-green {
  @apply text-green-600;
}

.icon-red {
  @apply text-red-600;
}

.compact-summary {
  @apply flex-1 ml-4 border-l pl-4;
}

.scenario-pills {
  @apply flex flex-wrap gap-2 mb-2;
}

.scenario-pill {
  @apply flex items-center gap-2 px-3 py-1 rounded-full text-sm font-medium;
}

.scenario-pill.high-prob {
  @apply bg-green-100 text-green-800;
}

.scenario-pill.medium-prob {
  @apply bg-yellow-100 text-yellow-800;
}

.scenario-pill .scenario-name {
  @apply truncate max-w-[200px];
}

.scenario-pill .probability {
  @apply text-xs opacity-75;
}

.sector-summary {
  @apply flex gap-6 text-sm text-gray-600;
}

.sector-group {
  @apply flex items-center gap-2;
}

.header-content {
  @apply flex items-center gap-2 cursor-pointer min-w-[200px];
} 