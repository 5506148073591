.google-login-button {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-right: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .google-login-button:hover {
    background-color: #f0f0f0;
  }
  