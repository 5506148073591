/* News Section Styles */
.news-section {
    margin-top: 0;
    width: 100%;
    max-width: 100%;
  }
  
  /* Carousel News Card */
  .news-carousel {
    position: relative;
    background: white;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
  }
  
  .news-carousel-content {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    transition: opacity 0.5s ease-in-out;
  }
  
  .news-thumbnail {
    width: 160px;
    flex-shrink: 0;
    display: block;
    overflow: hidden;
    border-radius: 0.375rem;
  }
  
  .news-thumbnail img {
    width: 100%;
    height: 90px;
    object-fit: cover;
    transition: transform 0.3s ease, opacity 0.2s ease;
  }
  
  .news-thumbnail:hover img {
    transform: scale(1.05);
  }
  
  /* Expanded Grid View */
  .news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
  }
  
  .news-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  }
  
  .news-card-image {
    height: 120px;
    overflow: hidden;
  }
  
  .news-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .news-card:hover .news-card-image img {
    transform: scale(1.05);
  }
  
  /* Carousel Navigation Dots */
  .carousel-dots {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .carousel-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #e5e7eb;
    transition: all 0.3s ease;
  }
  
  .carousel-dot.active {
    width: 16px;
    border-radius: 4px;
    background-color: #3b82f6;
  }
  
  /* News Content */
  .news-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }
  
  .news-source {
    color: #3b82f6;
    font-weight: 500;
  }
  
  .news-date {
    color: #6b7280;
  }
  
  .news-title {
    color: #1a202c;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: color 0.2s ease;
  }
  
  a:hover .news-title {
    color: #2563eb;
  }
  
  .news-description {
    color: #4b5563;
    font-size: 0.875rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .news-tickers {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .news-ticker {
    background-color: #eff6ff;
    color: #1d4ed8;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .news-ticker:hover {
    background-color: #dbeafe;
    transform: translateY(-1px);
  }
  
  .ticker-tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .earnings-estimate-tooltip {
    position: fixed;
    background-color: #1f2937;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    margin-bottom: 8px;
  }
  
  .earnings-estimate-tooltip::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
  }
  
  /* Add to existing NewsRow.css */
  
  .carousel-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-top: 1px solid #e5e7eb;
  }
  
  .carousel-controls-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .control-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #f3f4f6;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .control-button:hover {
    background: #e5e7eb;
  }
  
  .control-button svg {
    width: 16px;
    height: 16px;
    color: #374151;
  }
  
  .speed-controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: #f3f4f6;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
  }
  
  .speed-icon {
    width: 14px;
    height: 14px;
    color: #374151;
  }
  
  .speed-select {
    background: transparent;
    border: none;
    font-size: 0.75rem;
    color: #374151;
    cursor: pointer;
    padding-right: 0.5rem;
  }
  
  .speed-select:focus {
    outline: none;
  }
  
  .navigation-controls {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin: 0 0.5rem;
  }
  
  .navigation-controls .control-button {
    width: 24px;
    height: 24px;
    background: #f3f4f6;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .navigation-controls .control-button:hover {
    background: #e5e7eb;
  }
  
  .navigation-controls .control-button svg {
    width: 16px;
    height: 16px;
    color: #374151;
  }
  
  /* Minimal View Styles */
  .news-minimal-view {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-width: 0;
    margin-left: 1rem;
  }
  
  .news-minimal-thumbnail {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f3f4f6; /* bg-gray-100 */
  }
  
  .news-minimal-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .news-minimal-title {
    flex: 1;
    min-width: 0; /* Enables truncation */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #374151; /* text-gray-700 */
    transition: color 0.2s ease;
    font-size: 0.875rem; /* text-sm */
    line-height: 1.25rem;
  }
  
  .news-minimal-title:hover {
    color: #2563eb; /* text-blue-600 */
  }
  
  /* Header section styles */
  .news-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: none;
  }
  
  .news-header-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
    min-width: 0;
  }
  
  .news-toggle-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #2563eb;
    font-size: 0.875rem;
    transition: color 0.2s ease;
  }
  
  .news-toggle-button:hover {
    color: #1d4ed8;
  }
  
  /* Animation for view transitions */
  .news-content-transition {
    transition: all 0.3s ease-in-out;
  }
  
  .news-content-transition.minimal {
    max-height: 40px;
    overflow: hidden;
  }
  
  .news-content-transition.expanded {
    padding: 1rem;
  }