/* components/Footer.css */
.footer {
  background-color: #f8f9fa;
  color: #212529;
  padding: 20px 40px;
  border-top: 1px solid #dee2e6;
}

.footer-content {
  display: flex;
  justify-content: space-between; /* This will push the right section to the edge */
  align-items: flex-start;
  max-width: 1400px; /* Increased from 1200px */
  margin: 0 auto; /* Center the content */
  padding: 0 20px; /* Add some padding on the sides */
}

.footer-left {
  flex: 1.2; /* Increased from 1 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 60px; /* Increased from 40px */
}

.footer-right {
  flex: 2.2; /* Increased from 2 */
  max-width: 900px; /* Increased from 800px */
}

.footer-logo-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-logo-icon {
  font-size: 24px;
  color: #ffc107;
  margin-right: 10px;
}

.footer-logo-name {
  font-size: 18px;
  font-weight: bold;
  color: #212529;
}

.footer-made-with, .footer-copyright {
  margin-top: 5px;
  font-size: 0.8em;
  color: #888;
}

.footer-made-with .fa-heart {
  color: #e25555;
}

.footer-made-with .fa-fire {
  color: #ff9900;
}

.footer-disclaimer {
  font-size: 13px;
  color: #6c757d;
  line-height: 1.6;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: normal;
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.footer-disclaimer p {
  margin-bottom: 10px;
  text-align: left;
}

.footer-disclaimer p:last-child {
  margin-bottom: 0;
}

.footer-content .footer-left, .footer-content .footer-right {
  align-self: center;
}

.icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0 5px; /* Adds margin around each icon */
}

.icon-container .tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.footer-discord-link {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.footer-discord-link a {
  color: #7289da;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

.footer-discord-link a:hover {
  color: #5865f2;
}

.footer-discord-link .icon-container {
  font-size: 1.2em;
}

.footer-discord-link .tooltip {
  background-color: #7289da;
  color: white;
}

.footer-links {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 0.8em;
}

.footer-links a {
  color: #888;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #007bff;
}

.footer-logo-text {
  display: flex;
  flex-direction: column;
}

.footer-logo-subtitle {
  margin-top: 4px;
  font-size: 0.9em;
  color: #666;
  font-style: italic;
}