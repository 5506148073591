.landing-page {
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #ffffff;
}

.status-banner {
  background-color: #4285f4;
  color: white;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.9rem;
}

.hero {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  pointer-events: none;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}

.hero-logo {
  width: 100px;
  height: 100px;
  filter: drop-shadow(0 0 10px rgba(66, 133, 244, 0.3));
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ffffff 30%, #4285f4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
}

.hero h2 {
  font-size: 1.5rem;
  color: #e0e0e0;
  font-weight: 400;
}

.hero-story {
  margin: 2rem 0;
  background: #2d2d2d;
  border-radius: 8px;
  padding: 1.5rem;
}

.story-lead {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.story-points {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.story-point {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.story-point:hover {
  transform: translateY(-5px);
  background: rgba(66, 133, 244, 0.1);
}

.story-point i {
  font-size: 2rem;
  color: #4285f4;
  margin-bottom: 1rem;
}

.story-point h3 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.story-point p {
  color: #e0e0e0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.market-preview {
  background: #2d2d2d;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 2rem 0;
}

.section-title {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.market-indicators {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.indicator.success { color: #4caf50; }
.indicator.danger { color: #f44336; }
.indicator.warning { color: #ffd700; }

.indicator-value {
  font-weight: 500;
}

.indicator-label {
  opacity: 0.8;
  font-size: 0.9rem;
}

.scenario-tags {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.tag.success {
  background: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.tag.warning {
  background: rgba(255, 215, 0, 0.1);
  color: #ffd700;
}

.tag.danger {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.cta-button {
  background: linear-gradient(45deg, #4285f4, #34a853);
  color: white;
  border: none;
  padding: 1.2rem 2.5rem;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 2rem auto;
  display: block;
  min-width: 250px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(66, 133, 244, 0.3);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(66, 133, 244, 0.4);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.screener-categories {
  padding: 2rem;
  background: #1a1a1a;
}

.category-section {
  max-width: 1200px;
  margin: 0 auto;
}

.category-section h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.category-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.category-group {
  background: #2d2d2d;
  border-radius: 8px;
  padding: 1.5rem;
}

.category-group h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.category-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.category-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #e0e0e0;
}

.category-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.category-item i {
  font-size: 1rem;
  color: #4285f4;
}

.analysis-preview {
  padding: 2rem;
  background: #2d2d2d;
}

.analysis-content {
  max-width: 1200px;
  margin: 0 auto;
}

.analysis-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.analysis-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.analysis-feature {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  transition: background-color 0.2s;
}

.analysis-feature:hover {
  background: rgba(255, 255, 255, 0.08);
}

.analysis-feature i {
  font-size: 1.2rem;
  color: #4285f4;
  min-width: 24px;
  text-align: center;
}

.analysis-feature span {
  color: #e0e0e0;
  font-size: 1rem;
}

.landing-footer {
  background: #1a1a1a;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 3rem 2rem;
}

.landing-footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 4rem;
  align-items: start;
}

.landing-footer-logo-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.landing-footer-logo-img {
  width: 60px;
  height: 60px;
}

.landing-footer-logo-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.landing-footer-logo-name {
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
}

.landing-footer-logo-subtitle {
  color: #888;
  font-size: 0.9rem;
  margin: 0;
}

.landing-footer-center {
  padding-top: 0.5rem;
}

.landing-footer-center h4 {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.landing-footer-nav {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.landing-footer-nav a {
  color: #888;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.landing-footer-nav a:hover {
  color: #4285f4;
}

.landing-legal-links {
  margin-bottom: 2rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.landing-legal-links a {
  color: #888;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.landing-legal-links a:hover {
  color: #4285f4;
}

.landing-footer-discord-link {
  margin-top: 1rem;
}

.landing-footer-discord-link a {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #888;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.landing-footer-discord-link a:hover {
  color: #7289da;
}

.landing-footer-discord-link i {
  font-size: 1.2rem;
  color: #7289da;
}

.landing-copyright {
  color: #888;
  font-size: 0.85rem;
  margin-top: 2rem;
  text-align: right;
}

@media (max-width: 768px) {
  .landing-footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
    text-align: center;
  }

  .landing-footer-logo-section {
    flex-direction: column;
    align-items: center;
  }

  .landing-footer-logo-text {
    align-items: center;
  }

  .landing-footer-center {
    align-items: center;
  }

  .landing-footer-nav {
    align-items: center;
  }

  .landing-legal-links {
    justify-content: center;
  }

  .landing-footer-discord-link a {
    justify-content: center;
  }

  .landing-copyright {
    text-align: center;
  }
}

.stock-analysis-preview {
  padding: 3rem 2rem;
  background: #1a1a1a;
}

.analysis-content {
  max-width: 1200px;
  margin: 0 auto;
}

.analysis-content h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  color: #ffffff;
  text-align: center;
}

.analysis-demo {
  background: #2d2d2d;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  max-width: 1000px;
}

.demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: #1a1a1a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.stock-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.stock-logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.stock-info h3 {
  font-size: 1.2rem;
  color: #ffffff;
  margin: 0;
}

.company-link {
  color: #4285f4;
  font-size: 0.9rem;
  text-decoration: none;
}

.stock-metrics {
  display: flex;
  gap: 2rem;
}

.metric {
  text-align: right;
}

.metric .value {
  font-size: 1.2rem;
  font-weight: 500;
  color: #ffffff;
  display: block;
}

.metric .change {
  font-size: 0.9rem;
}

.change.positive { color: #4caf50; }
.change.negative { color: #f44336; }

.analysis-sections {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.analysis-section {
  background: #2d2d2d;
  padding: 1.5rem;
  border-radius: 0;
}

.analysis-section h4 {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.financial-metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.metric-group {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.metric-group-title {
  font-size: 0.85rem;
  color: #888;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-weight: 500;
}

.metric-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.35rem 0;
}

.metric-label {
  color: #e0e0e0;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.metric-label i {
  color: #666;
  font-size: 0.8rem;
}

.metric-value {
  font-size: 0.85rem;
  font-weight: 500;
}

.metric-value.positive { color: #4caf50; }
.metric-value.negative { color: #f44336; }
.metric-value.neutral { color: #e0e0e0; }

.range-analysis {
  padding: 1rem 0;
}

.price-range {
  position: relative;
}

.range-bar {
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  margin: 1rem 0;
  position: relative;
}

.range-indicator {
  width: 12px;
  height: 12px;
  background: #4285f4;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.range-label, .range-values {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #888;
}

.swot-content.compact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.swot-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.swot-category h5 {
  font-size: 0.85rem;
  color: #888;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
}

.swot-item {
  font-size: 0.85rem;
  color: #e0e0e0;
  padding: 0.35rem 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.key-developments {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.development-item {
  border-left: 2px solid #4285f4;
  padding-left: 1rem;
}

.development-item .date {
  color: #4285f4;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  display: block;
}

.development-item p {
  color: #e0e0e0;
  font-size: 0.9rem;
  margin: 0;
}

@media (max-width: 1024px) {
  .financial-metrics-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .swot-content.compact {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .demo-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .stock-metrics {
    width: 100%;
    justify-content: flex-start;
  }

  .analysis-sections {
    grid-template-columns: 1fr;
  }
}

.portfolio-analysis {
  padding: 3rem 2rem;
  background: #1a1a1a;
}

.portfolio-content {
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio-content h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.portfolio-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.portfolio-feature {
  background: #2d2d2d;
  border-radius: 8px;
  padding: 1.5rem;
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.feature-header i {
  font-size: 1.5rem;
  color: #4285f4;
}

.feature-header h3 {
  font-size: 1.2rem;
  color: #ffffff;
  margin: 0;
}

.health-meter {
  text-align: center;
  margin-bottom: 1.5rem;
}

.meter-value {
  font-size: 3rem;
  font-weight: 700;
  color: #4caf50;
}

.meter-label {
  color: #4caf50;
  font-size: 1.1rem;
}

.health-factors {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.factor {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
}

.factor.positive {
  color: #4caf50;
}

.factor.warning {
  color: #ffd700;
}

.performance-metrics {
  display: grid;
  gap: 1rem;
}

.performance-metrics .metric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.metric-label {
  color: #e0e0e0;
}

.metric-value {
  font-weight: 500;
}

.metric-value.positive {
  color: #4caf50;
}

.portfolio-insights {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.insight {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.insight i {
  font-size: 1.2rem;
  color: #4285f4;
}

.insight-content h4 {
  font-size: 1rem;
  color: #ffffff;
  margin: 0 0 0.5rem 0;
}

.insight-content p {
  font-size: 0.9rem;
  color: #e0e0e0;
  margin: 0;
}

@media (max-width: 768px) {
  .portfolio-analysis {
    padding: 2rem 1rem;
  }
  
  .portfolio-features {
    grid-template-columns: 1fr;
  }
}

.ai-lists-preview {
  padding: 3rem 2rem;
  background: #1a1a1a;
}

.ai-lists-content {
  max-width: 1200px;
  margin: 0 auto;
}

.ai-lists-content h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #ffffff;
}

.ai-demo {
  background: #2d2d2d;
  border-radius: 8px;
  padding: 2rem;
}

.ai-input {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
}

.input-preview {
  flex: 1;
  display: flex;
  gap: 1rem;
}

.input-preview i {
  font-size: 1.5rem;
  color: #4285f4;
}

.input-content {
  flex: 1;
}

.prompt-example {
  color: #e0e0e0;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(66, 133, 244, 0.1);
  border-radius: 4px;
  border-left: 3px solid #4285f4;
}

.example-lists {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  color: #e0e0e0;
}

.list-item i {
  color: #4285f4;
}

.create-list-btn {
  background: #4285f4;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.create-list-btn:hover {
  background: #3367d6;
  transform: translateY(-1px);
}

.ai-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.ai-feature {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.ai-feature i {
  font-size: 1.5rem;
  color: #4285f4;
}

.feature-text h3 {
  font-size: 1.1rem;
  color: #ffffff;
  margin: 0 0 0.5rem 0;
}

.feature-text p {
  font-size: 0.9rem;
  color: #e0e0e0;
  margin: 0;
}

@media (max-width: 768px) {
  .ai-input {
    flex-direction: column;
  }
  
  .create-list-btn {
    width: 100%;
    justify-content: center;
  }
  
  .example-lists {
    gap: 0.5rem;
  }
  
  .list-item {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}

.growth-analysis {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  color: #e0e0e0;
  line-height: 1.5;
}

.swot-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.swot-item {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.swot-item h5 {
  color: #4285f4;
  margin-bottom: 0.8rem;
  font-size: 1rem;
}

.swot-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #e0e0e0;
  font-size: 0.9rem;
}

.swot-item li {
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
  position: relative;
}

.swot-item li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #4285f4;
}

.key-developments {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.development-item {
  margin-bottom: 1rem;
}

.development-item .date {
  display: block;
  color: #4285f4;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.development-item p {
  color: #e0e0e0;
  margin: 0;
  line-height: 1.5;
}

.risk-analysis {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  color: #e0e0e0;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .swot-grid {
    grid-template-columns: 1fr;
  }
  
  .swot-item {
    margin-bottom: 1rem;
  }
}

.swot-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 1rem;
}

.swot-category {
  margin-bottom: 1rem;
}

.swot-category h5 {
  color: #4285f4;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.swot-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.swot-item {
  color: #e0e0e0;
  font-size: 0.9rem;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .swot-content {
    padding: 0.8rem;
  }
}

/* Make SWOT more compact */
.swot-content.compact {
  padding: 0.8rem;
}

.swot-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.swot-row:last-child {
  margin-bottom: 0;
}

.swot-content.compact .swot-category {
  margin-bottom: 0;
}

.swot-content.compact .swot-item {
  font-size: 0.85rem;
  padding: 0.3rem 0;
}

.technical-signals-showcase {
  padding: 3rem 2rem;
  background: #1a1a1a;
}

.signals-content {
  max-width: 1200px;
  margin: 0 auto;
}

.signals-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.signals-description {
  color: #e0e0e0;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.signal-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.signal-type-card {
  background: #2d2d2d;
  border-radius: 8px;
  padding: 1.5rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.signal-type-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.signal-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.signal-header i {
  font-size: 1.5rem;
  color: #4285f4;
}

.signal-header h3 {
  font-size: 1.1rem;
  color: #ffffff;
  margin: 0;
}

.signal-type-card p {
  color: #e0e0e0;
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.signal-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 4px;
}

.stat {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.stat .label {
  color: #888;
  font-size: 0.8rem;
}

.stat .value {
  color: #4285f4;
  font-size: 0.9rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .technical-signals-showcase {
    padding: 2rem 1rem;
  }
  
  .signal-types-grid {
    grid-template-columns: 1fr;
  }
  
  .signal-type-card {
    padding: 1.2rem;
  }
}

/* Add new hero-stats styles */
.hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 3rem 0;
}

.stat-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background: rgba(66, 133, 244, 0.1);
}

.stat-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: #4285f4;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: #e0e0e0;
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .financial-metrics-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.market-analysis-preview {
  padding: 4rem 2rem;
  background: #1a1a1a;
}

.market-content {
  max-width: 1200px;
  margin: 0 auto;
}

.market-content h2 {
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 2rem;
  text-align: center;
}

.market-overview-card {
  background: #2d2d2d;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.market-header {
  margin-bottom: 2rem;
}

.market-indicators {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
}

.indicator.positive {
  background: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.indicator.negative {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.indicator.neutral {
  background: rgba(255, 255, 255, 0.1);
  color: #e0e0e0;
}

.market-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.metric-tile {
  background: rgba(255, 255, 255, 0.05);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.metric-label {
  color: #888;
  font-size: 0.85rem;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 600;
}

.metric-value.fear { color: #ff9800; }
.metric-value.extreme-fear { color: #f44336; }
.metric-value.neutral { color: #e0e0e0; }
.metric-value.greed { color: #4caf50; }

.metric-tag {
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  width: fit-content;
  text-transform: uppercase;
}

.market-cycle {
  margin-top: 2rem;
}

.market-cycle h3 {
  color: #ffffff;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.cycle-phases {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.phase {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  opacity: 0.7;
}

.phase.active {
  opacity: 1;
  background: rgba(66, 133, 244, 0.1);
  border: 1px solid rgba(66, 133, 244, 0.2);
}

.phase-number {
  width: 24px;
  height: 24px;
  background: #4285f4;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 600;
}

.phase-content {
  flex: 1;
}

.phase-content h4 {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.phase-content p {
  color: #888;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.phase-details {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  font-size: 0.8rem;
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .market-indicators {
    flex-direction: column;
    gap: 1rem;
  }

  .market-metrics {
    grid-template-columns: 1fr;
  }

  .cycle-phases {
    grid-template-columns: 1fr;
  }
}

.cta-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-section h2 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ffffff 30%, #4285f4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-section p {
  color: #e0e0e0;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.future-price {
  color: #888 !important;
  font-size: 1rem !important;
  text-decoration: line-through;
}

.cta-features {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #e0e0e0;
}

.feature i {
  color: #4285f4;
}

.price-tag {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.original-price {
  color: #888;
  text-decoration: line-through;
  font-size: 1.5rem;
}

.current-price {
  color: #4caf50;
  font-size: 2.5rem;
  font-weight: 700;
}

.beta-label {
  background: rgba(76, 175, 80, 0.1);
  color: #4caf50;
  padding: 0.3rem 0.8rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.cta-button.pulse {
  animation: pulse 2s infinite;
}

.cta-button.glow {
  animation: glow 3s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(66, 133, 244, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(66, 133, 244, 0.6);
  }
  100% {
    box-shadow: 0 0 5px rgba(66, 133, 244, 0.3);
  }
}

.final-cta {
  background: linear-gradient(135deg, #2d2d2d 0%, #1a1a1a 100%);
}

@media (max-width: 768px) {
  .cta-features {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  
  .price-tag {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.beta-badge {
  background: linear-gradient(135deg, #4285f4, #34a853);
  color: white;
  padding: 0.4rem 1.2rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.value-prop {
  color: #e0e0e0;
  font-size: 1.2rem;
  margin: 1rem 0 2rem;
}

.beta-note, .access-note {
  color: #888;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.tier.active {
  background: rgba(66, 133, 244, 0.05);
  border: 1px solid rgba(66, 133, 244, 0.1);
}

.tier.future {
  opacity: 0.6;
  background: rgba(255, 255, 255, 0.02);
}

.cta-button {
  background: linear-gradient(45deg, #4285f4, #34a853);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(66, 133, 244, 0.2);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(66, 133, 244, 0.3);
}

.membership-tiers {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
  justify-content: center;
}

.tier {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  width: 300px;
}

.tier-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.tier-label {
  display: block;
  color: #888;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.tier-price {
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff;
}

.tier-features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tier-feature {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: #e0e0e0;
  font-size: 0.9rem;
}

.tier-feature i {
  color: #4285f4;
  font-size: 1rem;
}

.availability-note {
  color: #888;
  font-size: 0.85rem;
  margin-top: 1.5rem;
  font-style: italic;
}

@media (max-width: 768px) {
  .membership-tiers {
    flex-direction: column;
    align-items: center;
  }
  
  .tier {
    width: 100%;
    max-width: 300px;
  }
}