.disclaimer-message {
  background-color: #fffbeb;
  border: 1px solid #fef3c7;
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  margin: 1rem 0;
  font-size: 0.875rem;
  color: #92400e;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.disclaimer-message svg {
  color: #d97706;
  flex-shrink: 0;
}

.disclaimer-message .timestamp {
  color: #78350f;
  font-size: 0.75rem;
  margin-left: 0.25rem;
} 