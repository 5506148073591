.economic-event-controls {
    background-color: #f3f4f6;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e5e7eb;
  }
  
  .economic-event-controls .toggle-group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .economic-event-controls .toggle-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .economic-event-controls .toggle {
    position: relative;
    display: inline-block;
    width: 2.75rem;
    height: 1.5rem;
  }
  
  .economic-event-controls .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .economic-event-controls .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 1.5rem;
  }
  
  .economic-event-controls .toggle-slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.25rem;
    bottom: 0.25rem;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  .economic-event-controls .toggle input:checked + .toggle-slider {
    background-color: #3b82f6;
  }
  
  .economic-event-controls .toggle input:checked + .toggle-slider:before {
    transform: translateX(1.25rem);
  }
  
  .economic-event-controls .timeframe-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  .economic-event-controls .timeframe-button {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid #d1d5db;
    background-color: white;
    color: #374151;
  }
  
  .economic-event-controls .timeframe-button.active {
    background-color: #3b82f6;
    color: white;
    border-color: #3b82f6;
  }
  
  .economic-event-controls .timeframe-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .economic-event-controls .geography-select {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    border: 1px solid #d1d5db;
    background-color: white;
  }
  
  .economic-event-controls .geography-select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .analysis-panel {
    background-color: white;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .analysis-content {
    padding: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .analysis-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    margin-top: 1rem;
  }
  
  .analysis-main {
    background-color: #f8fafc;
    padding: 1.5rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  
  .analysis-sidebar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .impact-card {
    background-color: #f8fafc;
    padding: 1.25rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  
  .sentiment-meter {
    height: 8px;
    background-color: #e2e8f0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 0;
  }
  
  .sentiment-fill {
    height: 100%;
    transition: width 0.3s ease;
  }
  
  .economic-controls-header {
    transition: background-color 0.2s ease;
  }
  
  .economic-controls-header:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .controls-header {
    background: linear-gradient(to bottom, #ffffff, #f8fafc);
    border-bottom: 1px solid #e2e8f0;
    padding: 0.75rem 1.25rem;
  }
  
  .timeframe-button {
    transition: all 0.2s ease;
    font-weight: 500;
  }
  
  .timeframe-button:hover {
    transform: translateY(-1px);
  }
  
  .geography-select {
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 0.375rem 2rem 0.375rem 0.75rem;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
  }
  
  .sentiment-meter-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .sentiment-meter-container.compact {
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    min-width: 150px;
  }
  
  .sentiment-meter-container.compact .sentiment-meter {
    flex: 1;
    height: 6px;
  }
  
  .sentiment-label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .geography-select-container {
    display: flex;
    align-items: center;
    border-left: 1px solid #d1d5db;
    margin-left: 2rem;
    padding-left: 1.5rem;
  }