/* AI Filter Lists */
.ai-filter-list {
  background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
  border-left: 4px solid #3b82f6;
  position: relative;
  z-index: 1;
}

.ai-filter-menu-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-top: auto;
  border-top: 1px solid #e5e7eb;
  background: linear-gradient(135deg, #eff6ff 0%, #ffffff 100%);
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 1;
}

.ai-filter-menu-item .ai-icon {
  color: #3b82f6;
  margin-right: 0.75rem;
} 