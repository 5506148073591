/* Stock Page Header */
.stock-header {
  @apply bg-white rounded-lg shadow-sm p-4 mb-6;
}

.stock-header-content {
  @apply flex flex-col sm:flex-row items-start gap-4 sm:gap-6;
}

.stock-logo-and-info {
  @apply flex items-start gap-4 w-full;
}

.stock-logo {
  @apply flex-shrink-0 bg-gray-50 rounded-lg p-2;
}

.stock-logo img {
  @apply w-12 h-12 sm:w-14 sm:h-14 object-contain;
}

.stock-info {
  @apply flex-grow min-w-0;
}

.stock-title {
  @apply flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4;
}

.stock-title h1 {
  @apply text-xl sm:text-2xl font-bold text-gray-900 truncate;
}

.stock-price {
  @apply flex items-center gap-2 text-lg sm:text-xl;
}

.current-price {
  @apply font-semibold text-gray-900;
}

.price-change {
  @apply text-sm font-medium px-2 py-0.5 rounded;
}

.price-change.positive {
  @apply bg-green-50 text-green-700;
}

.price-change.negative {
  @apply bg-red-50 text-red-700;
}

.stock-subtitle {
  @apply flex flex-wrap items-center gap-3 text-sm text-gray-600;
}

.ticker {
  @apply font-medium bg-gray-100 px-2 py-0.5 rounded;
}

.industry {
  @apply text-gray-500;
}

.website-link {
  @apply flex items-center gap-1 text-blue-600 hover:text-blue-800 transition-colors;
}

.website-link i {
  @apply text-xs;
}

.stock-metrics {
  @apply grid grid-cols-2 sm:grid-cols-4 gap-4 w-full sm:w-auto mt-4 sm:mt-0 
         pt-4 sm:pt-0 border-t sm:border-t-0 border-gray-100;
}

.metric {
  @apply flex flex-col gap-1;
}

.metric span {
  @apply text-xs text-gray-500;
}

.metric strong {
  @apply text-sm font-medium text-gray-900;
}

.metric .range {
  @apply text-xs whitespace-nowrap;
}

/* Stock Page Sections */
.stock-section {
  @apply bg-white rounded-lg shadow p-6 mb-6;
}

.stock-section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.section-title {
  @apply text-xl font-semibold;
}

.stock-description {
  @apply text-gray-700 leading-relaxed;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .stock-header {
    @apply p-3;
  }
  
  .stock-metrics {
    @apply px-1;
  }
  
  .stock-title {
    @apply flex-col gap-1;
  }
} 