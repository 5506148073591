/* EconomicEventRow.css */

.economic-event-row {
  background-color: #f9fafb; /* Light gray background */
  border-left: 3px solid #3b82f6; /* Blue left border */
  transition: background-color 0.3s ease;
}

.economic-event-row:hover {
  background-color: #f3f4f6; /* Slightly darker on hover */
}

.economic-event-name {
  font-weight: 600;
  color: #1e40af; /* Dark blue for event name */
}

.economic-event-data {
  font-size: 0.875rem; /* Slightly smaller font for data */
}

.economic-event-actual {
  font-weight: 600;
}

.economic-event-forecast,
.economic-event-previous {
  color: #4b5563; /* Darker gray for forecast and previous */
}

.economic-event-chevron {
  transition: transform 0.3s ease;
  color: #9CA3AF; /* Tailwind's gray-400 */
}

.economic-event-row.expanded .economic-event-chevron {
  transform: rotate(180deg);
}

.economic-event-details {
  background-color: #ffffff;
  border-top: 1px solid #e5e7eb;
  padding: 1rem;
  font-size: 0.875rem;
  color: #4b5563;
}

/* Color coding for actual values */
.economic-value-positive {
  color: #059669; /* Green for positive values */
}

.economic-value-negative {
  color: #dc2626; /* Red for negative values */
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .economic-event-row {
    font-size: 0.75rem; /* Smaller font on mobile */
  }
  
  .economic-event-data {
    flex-direction: column;
    align-items: flex-start;
  }
}