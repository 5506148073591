.trading-range-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .trading-range-container {
    flex-direction: row;
    padding: 24px;
    align-items: flex-start;
  }
}

.graph-column {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .graph-column {
    width: 65%;
    margin-bottom: 0;
    margin-right: 24px;
  }
}

.insight-column {
  width: 100%;
}

@media (min-width: 768px) {
  .insight-column {
    width: 35%;
  }
}

.insight-box {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .insight-box {
    padding: 16px;
  }
}

.title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .title {
    font-size: 1.25rem;
    margin-bottom: 16px;
  }
}

.subtitle {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .subtitle {
    font-size: 1rem;
  }
}

.range-container {
  position: relative;
  height: 140px;
  margin-bottom: 24px;
  width: 100%;
  padding: 0 10px;
}

@media (min-width: 768px) {
  .range-container {
    height: 120px;
  }
}

.range-line {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 50%;
  height: 2px;
  background-color: #cbd5e0;
  z-index: 1;
}

.marker {
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  touch-action: none;
  z-index: 2;
}

.marker-endpoint {
  z-index: 1;
}

.marker-circle {
  width: 12px;
  height: 12px;
  background-color: black;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .marker-circle {
    width: 8px;
    height: 8px;
  }
}

.marker-current {
  width: 24px;
  height: 24px;
  background-color: #3b82f6;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .marker-current {
    width: 20px;
    height: 20px;
  }
}

.marker-average {
  width: 24px;
  height: 24px;
  background-color: #10b981;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

@media (min-width: 768px) {
  .marker-average {
    width: 20px;
    height: 20px;
  }
}

.marker-label {
  font-size: 0.75rem;
  white-space: nowrap;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease;
}

.marker-label-top {
  bottom: 100%;
  margin-bottom: 8px;
}

.marker-label-bottom {
  top: 100%;
  margin-top: 8px;
}

.marker:hover {
  z-index: 3;
}

.marker:hover .marker-label {
  font-weight: 600;
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .marker-label {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2px 4px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
}

@media (max-width: 767px) {
  .marker-current,
  .marker-average {
    width: 28px;
    height: 28px;
  }
  
  .marker-circle {
    width: 16px;
    height: 16px;
  }
}

.badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .badge {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}

.badge-bullish {
  background-color: #34D399;
  color: #064E3B;
}

.badge-bearish {
  background-color: #F87171;
  color: #7F1D1D;
}

.badge-consolidating {
  background-color: #60A5FA;
  color: #1E3A8A;
}

.badge-neutral {
  background-color: #E5E7EB;
  color: #374151;
}

.insight-text {
  font-size: 0.85rem;
  line-height: 1.5;
  color: #4a5568;
}

@media (min-width: 768px) {
  .insight-text {
    font-size: 0.9rem;
    line-height: 1.4;
  }
}

.placeholder-text {
  font-style: italic;
  color: #a0aec0;
} 